@import "../../../../App/assets/scss/colors.modules.scss";

.ForgotPasswordContainer {
  position: relative;
  max-width: 420px;
  width: 100%;
  max-height: 410px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  padding: 10px;
}

.Icon {
  background-color: $primary;
  margin: 16px;
}

.submit {
  margin: 12px;
  width: 100%;
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.SuccessMessage {
  color: #0f9960;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}
