body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Open Sans";
  // src: url("app/assets/fonts/ProximaNovaA-Regular.woff2") format("truetype");
}

@import "~@blueprintjs/core/lib/scss/variables";

$main: #2e2f30;

$pt-font-family: "ProximaNovaA", -apple-system, "BlinkMacSystemFont", "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue",
  "Icons16", sans-serif !default;
// Main colors
$pt-intent-primary: $main;
// $pt-intent-success: $main;
// $pt-intent-warning: $main;
// $pt-intent-danger: $main;
// $pt-app-background-color: $main;
// $pt-dark-app-background-color: $main;
// $pt-text-color: $main;
// $pt-text-color-muted: $main;
// $pt-text-color-disabled: $main;
// $pt-heading-color: $main;
$pt-link-color: $main;
// $pt-dark-text-color: $main;
// $pt-dark-text-color-muted: $main;
// $pt-dark-text-color-disabled: $main;
// $pt-dark-heading-color: $main;
// $pt-dark-link-color: $main;
$pt-text-selection-color: lighten($main, 60%);
// $pt-icon-color: $main;
// $pt-icon-color: $main;
// $pt-icon-color-disabled: $main;
// $pt-icon-color-selected: $main;
// $pt-dark-icon-color: $main;
// $pt-dark-icon-color: $main;
// $pt-dark-icon-color-disabled: $main;
// $pt-dark-icon-color-selected: $main;
// $pt-divider-black: $main;
// $pt-dark-divider-black: $main;
// $pt-dark-divider-white: $main;
// $pt-code-text-color: $main;
// $pt-code-background-color: $main;
// $pt-dark-code-text-color: $main;
// $pt-dark-code-background-color: $main;
// $pt-text-color: $main;

// second

$blue1: lighten($main, 2%);
$blue2: lighten($main, 4%);
$blue3: lighten($main, 90%);
$blue4: lighten($main, 10%);
$blue5: lighten($main, 12%);
$main50: lighten($main, 50%);

$light-gray1: #ffffff;
$light-gray2: #ffffff;
$light-gray3: #ffffff;
$light-gray4: #ffffff;
$light-gray5: #ffffff;

@import "~@blueprintjs/table/src/table.scss";

@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/popover2/src/blueprint-popover2.scss";
@import "~@blueprintjs/datetime/src/blueprint-datetime-modern.scss";

$main1: lighten($main, 2%);
$main5: lighten($main, 5%);
$main10: lighten($main, 10%);
$main10: lighten($main, 15%);
$main20: lighten($main, 20%);
$main25: lighten($main, 25%);
$main30: lighten($main, 30%);
$main35: lighten($main, 35%);
$main40: lighten($main, 40%);
$main45: lighten($main, 45%);
$main50: lighten($main, 50%);
$main55: lighten($main, 55%);

.#{$ns}-button:focus {
  outline: $main50 auto 2px;
  outline-offset: 2px;
}

[class*="-button"] {
  background-image: none !important;
}

.button-unoutline:focus {
  outline: none;
}

.#{$ns}-overlay {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  top: 0;
  z-index: 300;
}

.#{$ns}-overlay-content {
  position: fixed;
  z-index: 2000;
}

.#{$ns}-popover-content {
  padding: 2px;
}
.#{$ns}-datepicker,
.#{$ns}-daterangepicker {
  .DayPicker-Day--selected-range.DayPicker-Day--disabled {
    background: #ffc940;
    color: #a67908;
  }
  .DayPicker-Day--selected-range {
    background: #bfccd6;
    color: #5c7080;
  }
  .DayPicker-Day--hovered-range {
    background: #bfccd6;
    color: #5c7080;
  }
  .DayPicker-Day.DayPicker-Day--selected-range-end.DayPicker-Day--selected,
  .DayPicker-Day.DayPicker-Day--selected-range-start.DayPicker-Day--selected {
    background: #394b59;
    color: white;
  }
  .DayPicker-Day.DayPicker-Day--selected-range-start.DayPicker-Day--selected.DayPicker-Day--disabled {
    background: #a67908;
    color: white;
  }
}

// import 'react-day-picker/lib/style.css';
// @import "~react-day-picker/lib/style.css";
